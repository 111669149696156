import s from './LandingPage.module.scss';
import { Fragment, useEffect, useRef, useState } from 'react';
import OneUpLogo from '../../assets/images/LandingPage/Group 18219.svg';
import PoweredByLogo from '../../assets/images/LandingPage/IIFL Sec.svg';
import HeadstartImg from '../../assets/images/LandingPage/Headstart_338x288.svg';
import HeadstartMobileImg from '../../assets/images/LandingPage/Group 18083.svg';
import PatternImg from '../../assets/images/LandingPage/Pattern_328 px.svg';
import { getInDemandIssuesFromCms, getOpenIssuesList, getOpenIssuesDetails, getSubs, logOutUser, validthirdpartycheck, getSgbDetails } from '../../services/issuesServices';
import VideoSlider from '../UIComponents/VideoSlider';
import SectionSlider from '../UIComponents/SectionSlider';
import useWindowDimensions from '../../hooks/screenWidth';
import { useDispatch, useSelector } from 'react-redux';
import { clevertap_key } from '../../vars/url';
import { useHistory, useLocation } from 'react-router-dom';
import { loggedIn as userAction } from '../../store/action/loggedIn.action';
import { applicationData } from '../../store/action/applicationData';
import ClevertapReact from 'clevertap-react';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import LoaderImg from '../../assets/images/loader.svg';
import GsecSection from '../GsecDetails/LandingScreen/GsecSection';
import { ValidateUser } from '../../helpers/ValidateUser';
import SGBSection from '../SGBSection/SGBSection';
import Section1Card from '../Section1Card/Section1Card';
import Section1BigCard from '../Section1BigCard/Section1BigCard';
import { decryptionWithCryptoJS } from '../../helpers/decodeReferralCode';
import Footer from '../Footer/Footer';
import { actionGsecList } from '../../store/gsec/gsecAction';
import img1 from "../../assets/images/nps/img1.svg";
import img2 from "../../assets/images/nps/img2.svg";


const LandingPage = ({ toggleLogin }) => {

	const user = useSelector((state) => state.loggedIn);
	const applicationDataState = useSelector((state) => state.applicationData);
	const { width } = useWindowDimensions();
	const history = useHistory();
	const [aplynwlgin, setaplynwlgin] = useState('');
	const [notifyemail, setnotifyemail] = useState(user?.user?.email || '');
	let dispatch = useDispatch();
	const [inDemandCards, setInDemandCards] = useState([]);
	const [inDemandCards2, setInDemandCards2] = useState([]);
	const [sgbdata, setSgbData] = useState([])
	const [sgbprice, setSgbPrice] = useState(2770)
	const [leftTime, setLeftTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
	const [hidealert, setHideAlert] = useState(false)
	const [login, setLogin] = useState(false);
	const videoItems = useRef(['tbRdAJOcJqk', 'l2gTWyjId9g', 'weNwnd-sx_8', 'BYE6CycJW-s', 'bqDdDMCj05g']);
	const [SGBIssueList, setSGBIssueList] = useState([]);
	const [ipoissuelist, setipoissuelist] = useState([]);
	const [ncdissuelist, setncdissuelist] = useState([]);
	const [gsecissuelist, setgsecissuelist] = useState([]);
	const [indemandexist, setindemandexist] = useState(true);
	const [countipo, setcountipo] = useState(0);
	const [countncd, setcountncd] = useState(0);
	const [isallexist, setisallexist] = useState(true);
	const [loading, setLoading] = useState(false);
	const [toRedirect, setoRedirect] = useState('');
	const [swalert, setalert] = useState('');
	const [alertType, setalertType] = useState(false);
	const [npsLogin, setNpsLogin] = useState(false);

	const ReferralCode = new URLSearchParams(useLocation().search).get("ReferralCode");

	const location = useLocation();


	useEffect(() => {
		if (ReferralCode) {
			localStorage.setItem('ReferralCode', decryptionWithCryptoJS(location?.search.split('ReferralCode')[1]?.substring(1)));
		}
	}, [])


	const fetchIssues = (category) => {
		getOpenIssuesList(category)
			.then((result) => {
				if (result && result.data && result.data.isSuccess && result.data.statusCode === 200) {
					setSGBIssueList(result.data.resultData);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const fetchIssuesIPO = (category) => {
		getOpenIssuesList(category)
			.then((result) => {
				if (result && result.data && result.data.isSuccess && result.data.statusCode === 200) {
					setipoissuelist(result.data.resultData);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const fetchInDemand = async () => {
		try {
			let { data } = await getInDemandIssuesFromCms();
			let result = data.result
			if (data?.statusCode !== 200) {

				setindemandexist(false)
			}
			else {
				try {
					// result.map((e, i) => {
					// 	getSubs(e.co_code).then(response => {
					// 		let subres = []
					// 		subres = response?.data
					// 		var fres = subres.filter(function (v, i) {
					// 			return (v.Category == "Retail Individual Investors (RIIs)" && v.SubCategory == "");
					// 		})
					// 		result[i].subsdata = fres[0].NoOfTimesOfTotalMeantForTheCategory;
					// 	}).catch(error => {
					// 		console.log(error)
					// 	}).finally(() => {
					// 		setInDemandCards2(result);
					// 	})
					// })
				}
				catch (error) {
					alert(error)
				}

				// setInterval(() => {
				// 	setInDemandCards2(result);
				// }, 100);

			}

		} catch (error) {
			setindemandexist(false)
			console.log(error);
		}
	};

	const fetchthirdpart = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search)
		const urltoken = urlSearchParams.get('token');
		const requestercode = urlSearchParams.get('RequesterCode');
		let AppSource = urlSearchParams.get('AppSource');
		let redir = urlSearchParams.get('P');
		if (urltoken) {
			setLoading(true);
			try {
				let data = await validthirdpartycheck(urltoken, requestercode);
				localStorage.removeItem('WZRK_ARP');
				localStorage.removeItem('WZRK_EV');
				localStorage.removeItem('WZRK_G');
				localStorage.removeItem('WZRK_L');
				localStorage.removeItem('WZRK_META');
				localStorage.removeItem('WZRK_PR');

				if (data.data.statusCode == 200 && data.data.isSuccess == true) {

					var token = data.data.resultData.token;
					let clientdetails = {
						"panNo": "",
						"firstName": "",
						"fullname": data.data.resultData.clientName,
						"middleName": "",
						"surName": "",
						"mobileNo": data.data.resultData.mobile,
						"email": data.data.resultData.email,
						"clientcode": data.data.resultData.clientCode,
						"loginid": data.data.resultData.requesterCode,
						"dpType": "",
						"dpid": "",
						"beneficiaryID": "",
						"bankBranch": "",
						"bankName": data.data.resultData.bankName,
						"bankAccountNo": data.data.resultData.bankAccountNo,
						"upiHandle": data.data.resultData.upiHandle,
						"upiName": data.data.resultData.upiName,
						"investorStatus": "",
						"mkrid": "",
						"mkrDt": "",
						"dateOfbith": "",
						"address": "",
						"pincode": "",
						"secondHolderName": "",
						"secondHolderPan": "",
						"thirdHolderName": "",
						"thirdHolderPan": "",
						"issuecode": "",
						"leadid": "",
						"clientType": "IIFLCLIENT",
						"AppSource": AppSource,
					}
					localStorage.setItem('user', JSON.stringify({ ...clientdetails, token }));
					dispatch(userAction({ ...clientdetails, token }));


					ClevertapReact.initialize(clevertap_key);

					var payload = {
						"Site": {
							"Identity": data.data.resultData.clientCode,
							"email": '',
							"loginId": data.data.resultData.requesterCode,
							"mobileNo": '',
							"client-type": 'IIFLCLIENT',
							"client-code": data.data.resultData.clientCode,
						}
					}

					ClevertapReact.profile(payload)

					setTimeout(() => {
						if (redir == 'H') {
							window.location.replace("/your_applications");

						}
						else if (redir == 'L') {
							window.location.replace("/");
						}
						else if (redir?.length > 1) {
							setoRedirect(redir)
						}
						else {
							window.location.replace("/");
						}
					}, 1000);

					return false
				}
				else {
					alert('Invalid Session')
					setTimeout(() => {
						localStorage.removeItem('user');

						window.location.replace("/");
					}, 2000);
					alert('Invalid Session')
				}
			}
			catch (error) {
				alert(error);
			}
		}
		else {
			ClevertapReact.initialize(clevertap_key);
			ClevertapReact.event("OU_Home Page Viewed", {
				'path': window.location.hostname,
				'client-type': Object.keys(user).length > 0 ? user.user.clientType : 'Pre-login',
				'user-agent': navigator.userAgent,
				'app-source': Object.keys(user).length > 0 ? (user?.user?.AppSource ? user?.user?.AppSource : 25) : 25,
			})
		}
	}

	const fetchSgbDetails = () => {
		console.log('called in useffect landingpage')
		let response = getSgbDetails()
		response
			.then(res => {
				console.log('landingpagesgb', res.data.resultData[0])
				fetchSgbData(res.data.resultData[0])
				getRemainingTimeToApply(res.data.resultData[0].clsdt)
				setSgbPrice(res.data.resultData[0].highprice)
			})
			.catch(err => console.log(err))
	}
	const fetchSgbData = (data) => {
		setSgbData(data)
	}
	const handleSGBApply = () => {
		if (Object.keys(user).length > 0) {
			if (user.user.clientType === 'NONIIFLCLIENT') {
				setHideAlert(true)
			} else {
				setHideAlert(false)
				history.push('/sgb_apply')

			}
		} else {
			toggleLogin(!login)
		}

	}
	const handleALertBox = () => {
		setHideAlert(false)
	}

	const allexist = async () => {

		let { data } = await getOpenIssuesList('all');

		if (data.statusCode === 404) {
			setisallexist(false)
		}
		else {
			localStorage.setItem('alloppor', JSON.stringify(data.resultData));
			let ipolist = data.resultData.filter(function (v, i) {
				return (v.category.trim() == "IPO");
			})

			let ncdlist = data.resultData.filter(function (v, i) {
				return (v.category.trim() == "BOND");
			})

			let sgblist = data.resultData.filter(function (v, i) {
				return (v.category.trim() == "SGB");
			})

			let gseclist = data.resultData.filter(function (v, i) {
				return (v.category.trim() == "GS" || v.category.trim() == "TB" || v.category.trim() == "SD");
			})

			const descendOrder = gseclist.sort(({ indicativeYield: a }, { indicativeYield: b }) => b - a);


			setipoissuelist([...ipolist]);
			setncdissuelist([...ncdlist]);
			setgsecissuelist([...descendOrder]);

			dispatch(actionGsecList(gseclist));

			if (sgblist[0]) {
				fetchSgbData(sgblist[0])
				getRemainingTimeToApply(sgblist[0].clsdt)
				setSgbPrice(sgblist[0].highprice)
			}
		}
	}

	const redirectToDetails = async () => {

		if (toRedirect === '') {
			return false;
		}
		var allisue = JSON.parse(localStorage.getItem("alloppor"));
		if (allisue) {
			var isstyTypeNI = allisue.filter(function (v, i) {
				return (v.issuecode == toRedirect);
			})
			if (isstyTypeNI[0]?.category.trim() == "BOND") {
				window.location.replace("/ncd_details/" + toRedirect);
			}
			else if (isstyTypeNI[0]?.category.trim() == "IPO") {
				window.location.replace("/ipo_details/" + toRedirect);
			}
			else if (isstyTypeNI[0]?.category.trim() == "SGB") {
				window.location.replace("/sovereign_gold_bond_details/");
			}
			else {
				window.location.replace("/");
			}

		}
	}

	const finalizeIndemand = async () => {
		var allisue = JSON.parse(localStorage.getItem("alloppor"));
		if (allisue) {
			// Get array column name only 
			var issuecdarr = allisue.map(x => x['issuecode'])

			var indemFinal = inDemandCards2.filter(function (v, i) {
				return (issuecdarr.includes(v.issuecode));
			})
			setInDemandCards(indemFinal)

		}
	}

	useEffect(() => {
		fetchInDemand();
		allexist();
		fetchthirdpart();
		ValidateUser()
	}, []);

	useEffect(() => {
		redirectToDetails();
	}, [toRedirect]);


	useEffect(() => {
		finalizeIndemand();
	}, [inDemandCards2]);


	const [counter, setCounter] = useState(1);

	const handleCounter = (action) => {
		if (action === 'add') {
			setCounter((counter) => counter + 1);
			setSgbPrice(sgbdata.highprice * (counter + 1))
		} else {
			if (counter !== 1 && sgbprice >= sgbdata.highprice) {
				setCounter((counter) => counter - 1);
				setSgbPrice(sgbprice - sgbdata.highprice)
			}
		}
	};

	const getGoldReturnsEightYears = (goldprice) => {
		let totalReturns = (goldprice * counter) * [1 + (8 * 2.5) / 100] + (goldprice * counter) * (0.2);
		return Number(totalReturns).toLocaleString()
	}
	const getRemainingTimeToApply = (closeDate) => {
		// Set the date we're counting down to
		var countDownDate = new Date(closeDate).getTime();

		// Update the count down every 1 second
		// eslint-disable-next-line no-unused-vars
		var x = setInterval(function () {

			// Get today's date and time
			var now = new Date().getTime();

			// Find the distance between now and the count down date
			var distance = countDownDate - now;

			// Time calculations for days, hours, minutes and seconds
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);

			setLeftTime({ days: days, hours: hours, minutes: minutes, seconds: seconds })

		}, 1000);

	}

	const logoutuser = () => {
		let user = JSON.parse(localStorage.getItem('user'))
		if (user && user?.token) {
			logOutUser({ "Token": user?.token }).then(response => {
				if (response.data.isSuccess === true && response.data.statusCode === 200) {
					localStorage.removeItem('user');
					window.location.reload();
				}
				else {
					localStorage.removeItem('user');
					window.location.reload();
				}
			});
		}

	};

	const applyipoind = async (issuecode) => {
		ClevertapReact.initialize(clevertap_key);
		// ClevertapReact.event("In-Demand	Apply Now", {
		// 	'path': window.location.hostname,
		// 	'user-agent': navigator.userAgent,
		// 	'issue-Type': 'ipo',
		// 	'app-source': Object.keys(user).length > 0 ? (user?.user?.AppSource ? user?.user?.AppSource : 25) : 25,
		// 	'Source': 'Landing Page',
		// 	'Issuecode': issuecode
		// })

		ClevertapReact.event("OU_Indemand Click", {
			"Button Name": "Apply Now",
			"Issue Name": issuecode,
			'client-type': Object.keys(user).length > 0 ? user.user.clientType : 'Pre-login',
			'client-code': Object.keys(user).length > 0 ? user.user.clientcode : 'Pre-login',
		})


		if (aplynwlgin !== '' && Object.keys(user).length > 0) {
			let { data } = await getOpenIssuesDetails(issuecode);
			let details = data.resultData

			dispatch(
				applicationData({
					...applicationDataState,
					ipodetails: details,
					IPOBondName: details.issuecode,
					noOfShares: [parseInt(details.lotsize)],
					sharePrice: [details.highprice],
					bidPrice: [parseFloat(details.highprice)],
					totalbidprice: [parseFloat(details.highprice * details.lotsize)],
					cutOff: ["Y"],
					exchangetype: details.exchangeType
				})
			);

			history.push("/application_process")
		} else {

			setaplynwlgin(issuecode);
			if (Object.keys(user).length < 1) {
				toggleLogin();
			}
		}
	}

	useEffect(() => {
		if (aplynwlgin !== '') {
			if (Object.keys(user).length > 0) {
				applyipoind(aplynwlgin)
			}
		}

	}, [user, aplynwlgin]);

	const checkcountipo = (count) => {
		setcountipo(count)
	}

	const checkcountncd = (count) => {
		setcountncd(count)
	}



	const notifyme = () => {
		ClevertapReact.initialize(clevertap_key);
		var clienttype = 'Pre-Login'
		if (user?.user?.clientType) {
			clienttype = user?.user?.clientType
		}

		if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(notifyemail)) {
			setalert('invalid E-mail ID')
			setalertType(false);
			return false
		}

		var payload = {
			"Site": {
				"email": notifyemail,
				"client-type": clienttype,
				"interested": "Y",
				"client-code": user?.user?.panNo,
			}
		}
		ClevertapReact.profile(payload)
		setalert('You wil be notified if there is any opportunities')
		setalertType(true);
		setnotifyemail('');
	}

	const hidesweeetalert = () => {
		setalert('')
	}

	const handleNPSonClick = ()=>{
    if(!localStorage.getItem('user')){
      setNpsLogin(true)
      toggleLogin();
    }else{
      history.push('/nps-form')
    }
  }

  useEffect(()=>{
    if(localStorage.getItem('user')&& npsLogin){
      history.push('/nps-form')
    }
  },[localStorage.getItem('user')])

	return (
		<>
			<div className={s.main}>

				{swalert != '' &&
					<SweetAlert
						custom
						error
						success={alertType}
						title="Alert!"
						cancelBtnText="Back to home"
						cancelBtnBsStyle="light"
						onConfirm={hidesweeetalert}
					>
						{swalert}
					</SweetAlert>
				}

				{loading == true &&
					<div className="loading_screen loading_inside">
						<img src={LoaderImg} alt="loading..." />
					</div>
				}

				{width > 768 && <img src={PatternImg} alt="Pattern" className={s.patternImg} />}
				<div className={s.Ellipse245} />

				<nav className={s.navbar}>
					<div className={s.left}>
						{width > 768 && (
							<div className={s.logo} onClick={() => history.push("/")}>
								<LazyLoadImage
									alt="OneUp"
									effect="blur"
									src={OneUpLogo} />
								<span>Powered by
									<LazyLoadImage
										alt="OneUp"
										effect="blur"
										className={s.poweredBy}
										src={PoweredByLogo} />
								</span>
							</div>
						)}

						<h1>
							{Object.keys(user).length > 0 ? (
								<>
									Hello {user.user.fullname ? user.user.fullname : user.user.clientcode},<br />
									<span>There are great opportunities lined up for you today!</span>
								</>
							)
								:
								<>
									{width > 768 ? (
										'Your head-start on Incredible investment opportunities!'
									) : (
										'Head-start your investment opportunities!'
									)}
								</>
							}
						</h1>
					</div>

					<div className={s.right}>
						{width > 768 && (
							<Fragment>
								{Object.keys(user).length > 0 ? (
									<>
										<div className={s.loginbtns}>
											<button onClick={() => history.push("/your_applications")} className={s.loginstatus + " hover-animate"}>View Status</button>
											{(() => {
												if (user?.user?.AppSource != "1" && user?.user?.AppSource != "5" && user?.user?.AppSource != "6" && user?.user?.AppSource != "10" && user?.user?.AppSource != "0") {
													return <button className="hover-animate" onClick={() => logoutuser()}>Logout</button>
												}
											})()}
										</div>
									</>
								) : (
									<button className="hover-animate" onClick={() => {
										ClevertapReact.initialize(clevertap_key);
										ClevertapReact.event("OU_Login Completed", {
											Source: 'OneUp HomePage',
											"Issue Name": "",
										})
										toggleLogin();
									}}>Login</button>
								)}
							</Fragment>
						)}
						{width > 768 ? <LazyLoadImage
							alt="Headstart"
							effect="blur"
							delayTime="3000"
							src={HeadstartImg} /> : <LazyLoadImage
							alt={"Headstart"}
							effect="blur"
							delayTime="3000"
							src={HeadstartMobileImg} />}
					</div>
				</nav>

				{indemandexist &&
					<LazyLoadComponent>
						<section className={s.section1}>
							<h3 className={s.header1}>In Demand</h3>

							<div className={s.cards}>
								{inDemandCards.length < 1 &&
									<>
										<div className={s.shimmers}>
											<div className={s.shimmeritem}>
											</div>
											<div className={s.shimmeritem}>
											</div>
											<div className={s.shimmeritem}>
											</div>
										</div>
									</>
								}
								{inDemandCards.map((e, itm) => {
									return <Fragment key={itm}>{e.card_type === 1 && <Section1BigCard {...e} openipodt={ipoissuelist} user={user} applyipoind={applyipoind} />}</Fragment>;
								})}

								{inDemandCards.map((e, itm) => {
									return <Fragment key={itm}>{e.card_type === 0 && <Section1Card {...e} openipodt={ipoissuelist} user={user} applyipoind={applyipoind} />}</Fragment>;
								})}
							</div>
						</section>
					</LazyLoadComponent>
				}

				{!isallexist &&
					<LazyLoadComponent>
						<div className={s.notifyindemand}>
							<h2>Stay tuned for great <br /> opportunities lined up for you!</h2>
							<p>No investment options are available at the moment. But we will notify you as soon as <br /> any opportunities arise.</p>
							<div className={s.notifyform}>
								{(user?.user?.clientType === 'NONIIFLCLIENT' || Object.keys(user).length < 1) &&
									<input type='email' value={notifyemail} onChange={(e) => setnotifyemail(e.target.value)} placeholder='Enter Email ID' />
								}
								<button onClick={notifyme}>Notify Me!</button>
							</div>
						</div>
					</LazyLoadComponent>
				}

				{ipoissuelist.length > 0 &&
					<LazyLoadComponent>
						<SectionSlider
							section={2}
							items={ipoissuelist}
							heading={width > 768 ? 'Incredible market launches' : 'Incredible market launches'}
							toggleLogin={toggleLogin}
							checkcount={checkcountipo}
						/>
					</LazyLoadComponent>
				}

				{ncdissuelist.length > 0 &&
					<LazyLoadComponent>
						<SectionSlider
							section={3}
							items={ncdissuelist}
							heading={'Get fixed return with bonds'}
							toggleLogin={toggleLogin}
							checkcountncd={checkcountncd}
						/>
					</LazyLoadComponent>
				}

				{/* SGB Bonds  */}
				{sgbdata?.issuecode &&
					<SGBSection
						sgbdata={sgbdata}
						leftTime={leftTime}
						width={width}
						handleCounter={handleCounter}
						counter={counter}
						sgbprice={sgbprice}
						getGoldReturnsEightYears={getGoldReturnsEightYears}
						handleSGBApply={handleSGBApply}
					/>
				}

				<div className={s.gseclandingwrap}>
          {/* G sec part started */}
          {gsecissuelist.length > 0 && (
            <GsecSection toggleLogin={toggleLogin} details={gsecissuelist} />
          )}
          {/* G sec part ended */}
        </div>

				{/* nps card start */}
				<div className={s.nps_section}>
					<div className={s.heading}>
						National Pension System
					</div>
					<div className={s.crad_box}>
						{/* <div className={s.card}>
                <div className={s.imd_box}>
                  <img src={img1} alt="img" />
                </div>
                <div className={s.content}>
                  Begin NPS Investment Today
                </div>
              </div> */}
						<div onClick={handleNPSonClick} className={s.card}>
							<div className={s.imd_box}>
								<img src={img2} alt="img" />
							</div>
							<div className={s.content}>
								Top-Up Your NPS Now
							</div>
						</div>
					</div>
				</div>
				{/* nps card end */}

				{/* <LazyLoadComponent>
					<section className={s.section4}>
						<SectionSlider
							section={4}
							items={[]}
							heading={width > 768 ? 'If you had invested in these IPOs...' : 'If you had invested in these IPOs...'}
							toggleLogin={toggleLogin}
						/>
					</section>
				</LazyLoadComponent>

				<LazyLoadComponent effect="blur" delayTime="300">
					<VideoSlider items={videoItems.current} />
				</LazyLoadComponent> */}
			</div>
			<Footer />
		</>
	);
};

export default LandingPage;

